import { useState } from 'react'
import classNames from 'classnames'

import SEO from '@/components/shared/SEO'
import { useTeams } from '@/hooks/useMembers'
import Select from '@/components/shared/Select'

const Career = () => {
  const teams = useTeams()

  const [tabsMenu, setTabsMenu] = useState(
    teams.map((team, index) => ({
      title: `${team.name} (0)`,
      active: index === 0,
    }))
  )

  function activeTabMenu(menuIndex: number): void {
    setTabsMenu(
      tabsMenu.map((tab, index) => ({ ...tab, active: menuIndex === index }))
    )
  }

  return (
    <>
      <SEO title="Tuyển dụng" />
      <div className="flex overflow-hidden flex-col">
        <div className="flex relative flex-col base-container tablet:space-y-12 desktop:space-y-16">
          <div className="flex flex-col space-y-8 desktop:w-1/2">
            <h1 className="heading-2 md:heading-1">Tuyển dụng</h1>
            <div className="subheading-5 md:heading-4">
              Hiện không có vị trí tuyển dụng
            </div>
          </div>

          <div className="flex flex-col space-y-8 desktop:flex-row desktop:space-y-0 desktop:space-x-30">
            <div className="hidden flex-col flex-shrink-0 space-y-6 desktop:flex">
              {tabsMenu.map((tab, index) => (
                <div
                  key={tab.title}
                  className={classNames(
                    'py-6 border-b-2 transition cursor-pointer group',
                    { 'border-gray-300 hover:border-blue-500': !tab.active },
                    { 'border-blue-500': tab.active }
                  )}
                  onClick={() => activeTabMenu(index)}
                >
                  <h5
                    className={classNames('transition subheading-5', {
                      'text-gray-300 group-hover:text-blue-500': !tab.active,
                      'text-blue-500': tab.active,
                    })}
                  >
                    {tab.title}
                  </h5>
                </div>
              ))}
            </div>
            <div className="block w-full desktop:hidden tablet:w-1/2">
              <Select tabs={tabsMenu} onTabSelected={activeTabMenu} />
            </div>
            <div className="flex h-fit w-full p-6 border-[1px] border-blue-500">
              <h5 className="text-blue-500 subheading-5">
                Số lượng tuyển dụng: 0
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Career
