import { Chevron } from '@vhl/icons'
import { useEffect, useRef, useState } from 'react'
import { useClickAway, useToggle } from 'react-use'
import { AnimatePresence, motion } from 'framer-motion'

interface SelectProps {
  className?: string
  tabs: Array<{ title: string; active: boolean }>
  onTabSelected: (index: number) => void
}

const Select = ({ tabs, className, onTabSelected }: SelectProps) => {
  const [isSelectOpen, toggleSelect] = useToggle(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => toggleSelect(false))

  const [activateTabIndex, setActivateTabIndex] = useState<number>(0)

  useEffect(() => {
    setActivateTabIndex(tabs.findIndex(tab => tab.active))
  }, [tabs])
  return (
    <div className={className} ref={ref}>
      <div className="flex flex-col space-y-2">
        <div
          className="flex justify-between border-b-[1px] pb-2 border-gray-600"
          onClick={toggleSelect}
        >
          <h5 className="text-blue-500 heading-6">
            {tabs[activateTabIndex].title}
          </h5>
          <Chevron
            className={`stroke-gray-400 transform transition duration-300 ${isSelectOpen &&
              'rotate-180'}`}
          />
        </div>
      </div>
      <AnimatePresence>
        {isSelectOpen && (
          <motion.div
            className="absolute z-10 flex flex-col p-6 space-y-4 border-[1px] border-blue-500 w-full max-h-[180px] overflow-y-scroll bg-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {tabs.map((tab, index) => (
              <h5
                key={tab.title}
                onClick={() => {
                  onTabSelected(index)
                  toggleSelect()
                }}
                className={`heading-6 ${index === activateTabIndex &&
                  'text-blue-500'}`}
              >
                {tab.title}
              </h5>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Select
